import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ProjectHeader from "../components/project-header"
import ProjectBlurb from "../components/project-blurb"
import Img from "gatsby-image"


const info = {
    duration: "2012 - 2016",
    references: [
        {
            head: "Engineering Reference",
            name: "Brad Thomas",
            title: "Project Manager",
            employer: "HDR",
            phone: "(425) 245 9151",
        },
        {
            head: "Client Reference",
            name: "Roger Hansen",
            title: "Real Property Director",
            employer: "Sound Transit",
            phone: "(206) 370 5567",
        },
    ],
}

const Page = () => {
    const data = useStaticQuery(graphql`
      query {
        header: file(relativePath: { eq: "project-header--sound-transit.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        mapp: file(relativePath: { eq: "map--sound-transit.png" }) {
          childImageSharp {
            fluid(maxWidth: 680) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        extra: file(relativePath: { eq: "extra--sound-transit.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1040) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `)
    return (
        <Layout hideMenu={true}>
          <SEO title="East Link Project" />
          <ProjectHeader
            client="Sound Transit"
            project="East Link"
            image={data?.header?.childImageSharp?.fluid}
          />
          <ProjectBlurb data={info} mapImage={data.mapp.childImageSharp.fluid}>
            <p>
              The Project involves acquisitions of hundreds of
              commercial and residential parcels resulting in numerous
              relocation. RES Group NW worked on 24 commercial
              relocations for the East Link project. The commercial
              relocations varied from restaurants, industrial use
              properties, and office buildings. Ms. Davis and
              Ms. Guzman relocated 24 commercial relocates
              successfully, all relocation work was performed in
              accordance with the Federal Regulations and Sound
              Transit Policy, Procedures and Guidelines.
            </p>
          </ProjectBlurb>
          <Img
            className="extraMedia"
            alt="Project description"
            fluid={data.extra.childImageSharp.fluid} />
        </Layout>
    )
}

export default Page
